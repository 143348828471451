import React from 'react'
import PropTypes from 'prop-types'
import { SwiperSlide } from 'swiper/react'
import { useDeviceType } from '@frontastic/catwalk/src/js/helper/hooks/useDeviceType'
import Slider from '../../../atoms/slider'
import FullPageWidthWrapper from '../../../molecules/Layout/FullPageWidthWrapper'
import SmallTileItem from './SmallTileItem'

const SmallTileSlider = ({
    tileList, title, bgColor, swiperColor,
}) => {
    const isDesktop = useDeviceType() === 'desktop'

    const smallTileList = () => tileList.map((item, index) => {
        const { name, reference, media } = item

        if (isDesktop) {
            return (
                <SwiperSlide key={name}>
                    <SmallTileItem name={name} media={media} reference={reference} />
                </SwiperSlide>
            )
        }
        return (
            <SmallTileItem key={name} name={name} media={media} reference={reference} />
        )
    })

    const renderSmallTileByDevice = () => {
        if (isDesktop) {
            return (
                <Slider
                    swiperColor={swiperColor}
                    spaceBetween={24}
                    slidesPerView={6}
                    slidesPerGroup={6}
                    breakpoints={{
                        768: {
                            slidesPerView: 4,
                            slidesPerGroup: 4,
                        },
                        1024: {
                            slidesPerView: 6,
                            slidesPerGroup: 6,
                        },
                    }}
                >
                    {smallTileList()}
                </Slider>
            )
        }
        return (
            <div className={'small-tile-slider-mobile'}>
                {smallTileList()}
            </div>
        )
    }

    return (
        <FullPageWidthWrapper
            withWrapper
            style={{ backgroundColor: bgColor }}
        >
            <div className={'py-comp small-tile-slider-container'}>
                {title && <h2>{title}</h2>}
                {renderSmallTileByDevice()}
            </div>
        </FullPageWidthWrapper>
    )
}

SmallTileSlider.propTypes = {
    title: PropTypes.string,
    bgColor: PropTypes.string,
    tileList: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        reference: PropTypes.object,
        media: PropTypes.object,
    })),
    swiperColor: PropTypes.string.isRequired,
}

export default SmallTileSlider
