import React from 'react'
import PropTypes from 'prop-types'
import Reference from '@frontastic/catwalk/src/js/component/reference'
import FrontasticImage from '../../../atoms/frontasticImage'

const SmallTileItem = ({ media, name, reference }) => (
    <div className={'small-tile-item hover-zoomin-image'}>
        <Reference
            reference={reference}
        >
            {media && (
                <FrontasticImage
                    media={media.media}
                    title={name}
                    alt={name}
                />
            )}
            <h3>{name}</h3>
        </Reference>
    </div>
)
SmallTileItem.propTypes = {
    media: PropTypes.objectOf(PropTypes.any).isRequired,
    name: PropTypes.string.isRequired,
    reference: PropTypes.objectOf(PropTypes.any).isRequired,
}
export default SmallTileItem
